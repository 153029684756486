:root {
  --primary-color: #FCE1B9;
  --secondary-color: #EF494E;
  --secondary-color-light: #FBD6D7;
  --text-light: #808080;
  --text: #434343;
  --text-dark: #000000;
  --light-grey: #F2F3F3;
  --border: rgba(67,67,67, 0.2);
  --border-dark: rgba(67,67,67, 0.4);
}


