.header {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.icons-container {
    display: flex;
    padding: 0 2rem;
    height: 35px;
    border: 1px solid var(--border);
    box-shadow: 4px 10px 50px rgba(0, 0, 0, 0.04);
    border-radius: 5.78px;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    /* background-color: var(--primary-color); */

}

.icons-container i {
    font-size: 16px;
    color: var(--text);
    cursor: pointer;
    font-style: normal;
}

.icons-container span:hover,
.icons-container i:hover {
    color: var(--secondary-color);
}

@media only screen and (max-width: 600px) {
    .header {
        flex-direction:  column-reverse;
        gap: 1rem;
    }

    .icons-container {
        width: fit-content;
        align-self: flex-end;
    }
    
}