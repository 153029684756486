.input-label {
  display: flex;
  align-items: center;
}

.input-label label {
  flex: 1;
  color: var(--text) !important;
}

.input-content {
  display: flex;
  align-items: center;
  position: relative;
}

.input-content .countries {
  margin-right: 15px;
}

.input-content input {
  flex: 1;
  border: 1px solid var(--border);
  height: 35px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text);
  box-shadow: 4px 10px 50px rgba(0, 0, 0, 0.04);
}

.form-group {
  margin-bottom: 15px;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border-dark);
}

.input-content .right-box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1rem;
}

.input-content .right-box a {
  text-decoration: 0;
  font-weight: 500;
}

.left-icon {
    padding-left: 2.5rem;
}

label {
  margin-bottom: 8px;
}

.required {
  color: var(--secondary-color);
}

.no-border-bottom > .form-group {
  margin-bottom: 0 !important;
}

.input-content .iconRight {
  position: absolute;
  right: 0;
  width: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-content .iconLeft {
    position: absolute;
    left: 0.5rem;
    width: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.selector__value-container--is-multi {
  max-height: 2.5rem;
  overflow-y: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.selector__value-container--is-multi::-webkit-scrollbar {
  display: none;
}

.form-control {
  font-weight: 400;
  color: var(--text);
}

.hidden {
  display: none;
}
  