.modal-header {
  background-color: var(--light-grey);
  border-bottom: 0;
}

.modal-footer {
  background-color: var(--light-grey);
  padding-right: 1rem !important;
  display: flex;
  gap: 1rem;
}

.modal_title {
  font-size: 16px;
  color: var(--secondary-color);
}
