.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: 700;
    /* padding: 0.5rem; */
}

.pagination-container p {
    margin: 0 !important;
}

.pagination-container button {
    display: flex;
    gap: 0.5rem;
    background-color: var(--white);
    border: none;
    color: var(--text);
    font-weight: 500;
    padding: 3px 6px;
    border-radius: 5px;
}

.pagination-container button:disabled:hover,
.pagination-container button:disabled {
    color: var(--text-light);
    opacity: 0.5;
}

.pagination-container button:hover {
    color: var(--secondary-color);
    background-color: var(--white);
    outline: none;
}

.page-counter {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    background-color: var(--light-grey);
    padding: 8px 16px;
    border-radius: 2px;
    width: auto;
    font-size: 14px;
}

.total-count {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}