.layout {
    height: 100%;
    width: 100%;
}

.main-section {
    height: 100vh;
    width: 100%;
    overflow-y: hidden;
    flex: 1;
}

section {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 100px;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 1rem;
}