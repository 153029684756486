.table-td {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
}
.table-header {
    padding: auto 0;
    align-items: center;
}

.table-border {
    border: 1px solid var(--border);
}

th {
    color: var(--text-dark);
    font-weight: 700;
    font-size: 14px;
}

td, td > span {
    color: var(--text);
    font-weight: 400;
    font-size: 13px;
}