
.toggleBtn {
    cursor: pointer;
}

nav h1, nav span {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-dark);
    margin: 0;
}

.navbar span {
    color: #FFFFFF;
}

.navbar-bell {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 10px 15px rgba(151, 151, 151, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav-items {
    display: flex;
    flex-direction: row;
}

.navbar-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--secondary-color);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.navbar-avatar a {
    text-decoration: none;
}

.navbar a {
    text-decoration: none;
}

@media only screen and (min-width : 1025px) {
    .nav-items {
        padding: 0.5rem;
    }
}