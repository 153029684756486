.sidebar {
  width: 236px;
  height: 100vh;
  display: flex;
  gap: 62px;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  flex-shrink: 0;
  transition: width ease 300ms;
  background-color: var(--light-grey);
}

.sidebar.hide {
  width: 0;
  visibility: hidden;
}


.sidebar .top .toggle {
  opacity: 0.5;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}
  
.sidebar .top .toggle i {
  font-size: 1.1rem;
}

.sidebar .top .logo {
  padding: 0 0.5rem;
}

.middle {
  flex: 1;
  overflow-y: auto;
}

.sidebar ul {
  margin: 0;
  padding: 0;
}

.sidebar ul li {
  list-style: none;
  padding: 6px 0;
}

.sidebar ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-light);
  margin: 0 1rem;
  border-radius: 0.3rem;
  min-height: 2.5rem;
  padding: 0 0.3rem;
}

.sidebar ul li a i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.sidebar ul li a span {
  margin: 0 0.5rem;
  flex: 1;
  font-weight: 600;
  font-size: 16px;

}

.sidebar ul li a:hover,
.sidebar ul li a.active {
  opacity: 1;
  color: var(--secondary-color);
  font-weight: 500;
  /* background-color: var(--secondary-color-light); */
}

.sidebar .active {
  color:  var(--secondary-color);
}

@media screen and (max-width: 600px) {
  .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200px;
    z-index: 999;
    left: -200px;
    transition: 200ms;
  }

  .sidebar.show {
    left: 0;
  }

  .sidebar.top .toggle {
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar .show::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: 200px;
  }
}