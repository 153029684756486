/* button styles */
.btn {
  margin: 8px 0;
  border: 1px solid transparent;
  white-space: nowrap;
}

.btn-primary {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color);
  border: none;
  opacity: 0.9;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  border: none;
  outline: none;
  opacity: 1;
}

.btn-delete {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  box-shadow: none;
  border: none;
}

.btn-delete:hover,
.btn-delete:active,
.btn-delete:focus {
  border: none;
}

.btn-secondary {
  background-color: var(--primary-color) !important;
  color: var(--secondary-color);
  outline: none;
  border: none;
  opacity: 0.9;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  outline: none;
  box-shadow: none;
  color: var(--secondary-color);
  opacity: 1;

}

.btn.circle {
  border-radius: 30px;
}

.btn-sm {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:disabled,
.btn[disabled] {
  background-color: var(--gray-light) !important;
  border: 1px solid var(--gray-light) !important;
  color: var(--text);
}

.btn i {
  font-size: 15px;
  position: relative;
  top: 0.13rem;
}














.search-dropdown {
  box-shadow: var(--gray-light);
  /* border: 1px solid var(--secondary-color-light); */
}

/* .search-dropdown
  .search-dropdown-select
  .search-dropdown
  .search-dropdown-select
  .content {
  overflow-y: auto;
  max-height: 300px;
} */

.search-dropdown ul {
  padding: 0;
  margin: 0;
}

.search-dropdown ul li {
  list-style: none;
  min-height: 50px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  border-bottom: 1px solid var(--gray-light);
  padding: 0 1rem;
}

.search-dropdown ul li:hover,
.search-dropdown ul li.active {
  cursor: pointer;
  background-color: var(--secondary-color-light);
}

.search-dropdown ul li span {
  flex: 1;
  font-size: 14px;
  max-width: 300px;
}

.custom-checkbox {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-light);
  margin-right: 1rem;
  border-radius: 4px;
}

.custom-checkbox.active {
  background-color: var(--secondary-color);
}

.custom-checkbox.active i {
  color: white;
}

.search-drop-input {
  padding: 1rem 1rem 0 1rem;
  position: relative;
}

.clear-drop-search {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.clear-drop-search a:hover {
  text-decoration: underline;
}

.search-dropdown-text-selected {
  max-width: 100px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer_options {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.footer_options span.count {
  flex: 1;
  font-weight: 600;
  color: var(--secondary-color);
  margin-right: 1rem;
  font-size: 14px;
}

.footer_options .buttons {
  display: flex;
  align-items: center;
}

.rem_selected_v {
  position: absolute;
  right: 0;
}

.drop-btn {
  background-color: var(--primary-color);
  color: var(--white);
}

.dropdown-menu {
  /* white-space: nowrap; */
  /* max-width: 400px; */
}

.search-dropdown .content {
  max-height: 300px;
  overflow-y: auto;
  white-space: nowrap;
  overflow-x: hidden;
}

