.apps-container {
    display: flex;
    flex-direction: row;
}

.apps-body {
    flex: 2;
    padding: 1rem 0.5rem;
}

.selected-app {
    flex: 1;
    padding: 1rem 0.5rem;
    transition: width ease 700ms;
}

.app-card {
    width: 240px;
    height: 260px;
    background: #FFFFFF;
    border: 0.8px solid rgba(218, 219, 219, 0.4);
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
    border-radius: 5.75px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    overflow: hidden;
    transition: width ease 700ms;

    /* gap: 1rem; */
}

.app-image {
    background-color: var(--primary-color); 
    width: 100%;
    flex: 2;
}

.app-image img {
    /* height: 60px; */
    /* width: 157px; */
}

.app-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1rem;

}

.app-body h3 {
    font-size: 18px;
    font-weight: 500;

}

.app-body p {
    font-size: 14px;
    font-weight: 400;

}
.app-card:hover {
    border: 2px solid var(--primary-color);
    transition: all ease-out 300ms;
}

.apps-body {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: start;
    transition: all 300ms ease;
}

.app-link {
    color: var(--secondary-color);
    
}


/* App */

.application-body {
    border: 0.8px solid rgba(218, 219, 219, 0.4);
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.05);
    /* height: 100%; */
}

.top {
    display: flex;
    justify-content: flex-end;
}

.close-btn {
    cursor: pointer;
}

.app-form {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    align-self: stretch;
}

.app-form h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 160%;
}

.app-form p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--text-light);
}

@media only screen and (max-width: 1368px) {
    .apps-body {
        flex: 1;
    }
}