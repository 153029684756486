.splash_body {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey);
}

.error-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}