@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', 'sans-serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  font-size: 14px;
}